import styled from "styled-components";
import { homeInputStyle } from "../../assets/static/index";
import { TextBtn } from "../../assets/static/static.css";

// import DownUnselectedIcon from "../../assets/image/down-unselected.png";
// import DownSelectedIcon from "../../assets/image/down-selected.png";
// import UpUnselectedIcon from "../../assets/image/up-unselected.png";
// import UpSelectedIcon from "../../assets/image/up-selected.png";
// import symlog from "d3-scale/src/symlog";

export const FiltersContainer = styled.div`
  // position: absolute;
  display: flex;
  align-items: flex-start;
  gap: 1vw;
  font-size: ${(props) => props.theme.bodyText.fontSize};
  font-weight: ${(props) => props.theme.bodyText.fontWeight};
  width: ${props => props.alignCenter && 'fit-content'};
  margin: ${props => props.alignCenter && '0 auto'};
`;

export const FilterWrapper = styled.div`
  box-shadow: 3px 12px 20px rgba(88, 158, 195, 0.15);
  background: transparent;
  /* border-radius: ${(props) => (props.filterState ? "20px" : "18rem")}; */
  border-radius: 10px;
  line-height: auto;
  color: ${(props) => (props.ifApplied ? "#FAFAF9" : "#2D6291")};

  max-height: ${(props) => (props.ifOpen ? "34vh" : "3.4vh")};
  /* margin-right: 1vw; */
  min-width: 4vw;
  /* max-width: ${(props) => (props.ifOpen ? "fit-content" : "9vw")}; */
  max-width: 14vw;
  width: fit-content;
  /* width: ${(props) => (props.ifOpen ? "fit-content" : "calc(9vw-4vw)")}; */
  padding: 0.3vh 1.2vw 0.2vh 1.2vw;
  padding-bottom: ${(props) => (props.ifOpen ? "5px" : "0px")};
  background-color: ${(props) =>
    props.ifApplied ? props.theme.colors.commonBlue : "#FAFAF9"};
  overflow: hidden;

  transition: max-height 0.2s;

  &:hover {
    background-color: ${(props) =>
      (!props.ifApplied && !props.ifOpen && "#bcdeeb") ||
      (props.ifApplied && !props.ifOpen && "#84BAC5")};
    cursor: pointer;
  }
`;

export const FilterLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1vw;
  height: 3vh;
  box-sizing: border-box;
  font-size: ${(props) => props.theme.heading4.fontSize};
  white-space: nowrap;

  & > span:first-child {
    display: flex;
    align-items: center;
    gap: 0.4vw;
    flex-grow: 1;
  }
`;

export const FilterSearch = styled.input`
  text-decoration: none;
  outline: 0px;
  border: 0px;
  text-shadow: 2px;
  font-size: 0.85rem;
  background: inherit;
  color: inherit;
  width: 80%;
  padding: 0px;
  ::placeholder {
    color: inherit;
  }
`;

export const FilterIcon = styled.span`
  font-size: ${(props) => props.theme.bodyText.fontSize};
  transform: ${(props) => (props.ifOpen ? "rotate(0deg)" : "rotate(180deg)")};
  transition: transform 0.4s;
`;

export const FilterOptionWrapper = styled.ul`
  /* margin: auto; */
  margin: 5px 0;
  padding: 0;

  border: 1vh solid "#549EB0";
  font-family: "Libre Franklin";
  font-style: normal;
  font-weight: 400;

  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5vh;

  li:first-child {
    font-size: 0.8rem;
    padding: 0.2rem 0.2rem 0.2rem 0.2rem;
  }
`;

export const LoadingFilter = styled.div`
  font-size: 0.8rem;
  margin: auto;
`;

export const FilterOptions = styled.li`
  height: auto;
  list-style: none;

  display: flex;
  /* align-items: center; */
  gap: 1vh;
  font-family: "Libre Franklin";
  font-style: normal;
  font-weight: 400;

  width: 90%;
  padding: 0.2rem 0.15rem 0.2rem 0.1rem;
  overflow-x: hidden;

  cursor: ${(props) => (props.ifSelectable ? "pointer" : "not-allowed")};

  color: ${(props) =>
    props.ifApplied
      ? (props) => (props.ifSelectable ? "#FAFAF9" : "#1A3843")
      : (props) => (props.ifSelectable ? "#2D6291" : "#C4C4C4")};
  &:hover {
    background-color: ${(props) =>
      props.ifApplied
        ? (props) => (props.ifSelectable ? "#366672" : "inherit")
        : (props) => (props.ifSelectable ? "#bcdeeb" : "inherit")};
    border-radius: 6px;
  }

  span:first-child {
    /* font-size: 22rem; */
    height: 0.8vw;
    min-width: max(0.8vw, 10px);
    min-height: 10px;
    /* padding: 4px; */
    border: 1px solid #2d6291;
    border-color: ${(props) =>
      props.ifApplied
        ? (props) => (props.ifSelectable ? "#FAFAF9" : "#020202")
        : (props) => (props.ifSelectable ? "#2D6291" : "#C4C4C4")};

    border-radius: ${(props) => (props.isSingleSelect ? "50%" : "4px")};

    display: flex;
    align-items: center;
    justify-content: center;
  }

  span:last-child {
    font-size: 0.7rem;
    text-align: left;
    /* white-space: nowrap; */
  }
`;

export const SingleFill = styled.div`
  height: 80%;
  width: 80%;
  background-color: #fafaf9;
  opacity: 0.7;
  border-radius: 50%;
`;

// export const FilterIcon = styled.img.attrs(({ ifApplied, ifOpen }) => ({
//   src: ifApplied
//     ? ifOpen
//       ? UpSelectedIcon
//       : DownSelectedIcon
//     : ifOpen
//     ? UpUnselectedIcon
//     : DownUnselectedIcon,
// }))`
//   width: 10px;
//   height: 6px;
// `;

export const FilterInput = styled.input`
  ${homeInputStyle}
  padding: 0;
  text-align: left;
  width: 74%;
  height: 3.5vh;

  &::placeholder {
    color: inherit;
  }
`;

export const OptionsMenu = styled.div`
  box-sizing: border-box;
  list-style: none;
  /* padding: 10px 20px 10px 10px; */
  // margin: 10px 2px;
  width: 100%;
  max-width: 15vw;
  max-height: 25vh;
  height: auto;

  border-top: 1px solid #437e8d;

  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0.4rem;
  }

  &::-webkit-scrollbar-track {
    /* background-color: rgb(221, 221, 221);
        border-radius: 100vw; */
    margin-top: 0.4em;
  }

  /* &::-webkit-scrollbar-thumb {
        background-color: rgb(170, 170, 170);
        border-radius: 100vw;
    } */

  /* &::-webkit-scrollbar-thumb:hover {
        background-color: rgb(190, 190, 190);
    } */
`;

export const OptionItem = styled.li`
  width: 85%;
  padding: 2px 1px 2px 5px;
  cursor: ${(props) => (props.ifSelectable ? "pointer" : "default")};
  color: ${(props) =>
    props.ifApplied
      ? (props) => (props.ifSelectable ? "#FAFAF9" : "#B7B0D3")
      : (props) => (props.ifSelectable ? "#381650" : "#C4C4C4")};
  overflow-x: hidden;

  &:hover {
    background-color: ${(props) =>
      props.ifApplied
        ? (props) => (props.ifSelectable ? "#5C2583" : "inherit")
        : (props) => (props.ifSelectable ? "#EEE9F2" : "inherit")};
    border-radius: 6px;
  }
`;

export const ResetFilter = styled.div`
  box-sizing: border-box;
  width: fit-content;
  line-height: 3.5vh;
  color: #468aab;
  font-size: ${(props) => props.theme.heading4.fontSize};
  text-decoration: none;
  white-space: nowrap;

  &:hover {
    cursor: pointer;
  }
`;
