import { configureStore } from "@reduxjs/toolkit";

import { combineReducers } from "redux";

import opportunitiesFilterReducer from "./Opportunities/filtersReducer";
import opportunitiesOptionReducer from "./Opportunities/optionsReducer";

import clinicalTrialsFilterReducer from "./ClinicalTrials/filtersReducer";
import clinicalTrialsOptionReducer from "./ClinicalTrials/optionsReducer";

import { authLoginReducer } from "./Auth/loginSlice";
import { authCreateAccountReducer } from "./Auth/createAccountSlice";
import { authForgotPasswordReducer } from "./Auth/forgotPasswordSlice";
import { authResetPasswordReducer } from "./Auth/resetPasswordSlice";
import { authSetPasswordReducer } from "./Auth/setPasswordSlice";

const reducers = combineReducers({
  opportunitiesFilter: opportunitiesFilterReducer,
  opportunitiesOptions: opportunitiesOptionReducer,

  clinicalFilter: clinicalTrialsFilterReducer,
  clinicalOptions: clinicalTrialsOptionReducer,

  authLogin: authLoginReducer,
  authForgotPassword: authForgotPasswordReducer,
  authSetPassword: authSetPasswordReducer,
  authResetPassword: authResetPasswordReducer,
  authCreateAccount: authCreateAccountReducer,
});

const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
});

export default store;
