import styled from "styled-components";


export const RatingsWrapper = styled.div`

    &::after, &::before{
        --scale: 0;
        --tooltip-color: #DBEAF3;
        --arrow-size: 7px;

        position: absolute;
        left:50%;
        bottom: -.2rem;
        outline: none;
        /* z-index: 1; */

        max-height: 17vh;
        overflow-y: auto;
        whiteSpace: pre-line;

        
        transform: translateX(-50%) translateY(var(--translate-y,0)) scale(var(--scale));
        transition: 100ms transform;

    }
    
    &::before{
        --translate-y: calc(100% + var(--arrow-size));
        
        content: attr(data-tooltip);
        color:  black;
        padding: .7vw;
        width: max-content;
        max-width: 20vw;
        height: auto;
        background: var(--tooltip-color);
        // box-shadow: 0 0 5px var(--tooltip-color);
        font-size: ${props=> props.theme.bodyText.fontSize};
        font-weight: ${props=> props.theme.bodyText.fontWeight};
        line-height: 1.6em;
        border-radius: 6px;

        transform-origin: top center;
    }

    &:hover::before,
    &:hover::after{
        --scale: 1;
        z-index: 1;
    }

    &::after{
        --translate-y: var(--arrow-size);
        whiteSpace: pre-line;

        content: '';
        border: 6px solid transparent;
        border-bottom-color: var(--tooltip-color);
        transform-origin: bottom center;
    }
`;