import React, { useEffect } from "react";
import Layout from "layout/opportunitiesLayout";
import { useState } from "react";
import { fetchOpportunitiesListFilters, queryOpportunitiesList, sendOpportunityFeedback } from "../utils/requests";
import { Pagination, TopScrollBtn } from "components";
import { useAuth } from "contexts/AuthContext";
import { useNavigate } from "react-router";
import useFilter from "../hooks/useFilter";
import {
  TableContainer
} from "../components/style.components";
import TableComponent from "../components/TableComponent";

function Opportunities(props) {
  const SEARCHBAR_PLACEHOLDER = "Search by Opportunities name or Keyword";
  const TABLE_PAGE_RECORD_COUNT = 40;

  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const { currentUser, userLogOut } = useAuth();
  const [searchText, setSearchText] = useState("");

  const [showNoDataText, setShowNoDataText] = useState(false);

  const [data, setData] = useState({
    currentPage: 1,
    pageCount: 1,
    list: [],
  });

  const {
    filters,
    setFilterByKey,
    resetFilters,
    options,
    setOptionsWithResults,
  } = useFilter('Opportunities');

  useEffect(() => {
    queryFilteredData();
  }, [filters]);

  const queryFilteredData = (newPage = data.currentPage) => {
    const pageToQuery = newPage == data.currentPage ? 1 : newPage;
    setLoading(true);

    let filtersData = JSON.parse(JSON.stringify(filters));
    filtersData["Search"] = searchText;

    const dataString = JSON.stringify({
      filters: filtersData,
      pageNum: pageToQuery,
    });

    fetchOpportunitiesListFilters(dataString, currentUser, userLogOut)
      .then((results) => {
        results["Matches"] = [['Yes', true]];
        setOptionsWithResults(results);
      })
      .catch((err) =>
        console.error(`Error Occurred during fetch: ${err.message}`)
      )
      .finally(() => setLoading(false));

    queryOpportunitiesList(dataString, currentUser)
      .then(({results, statusCode}) => {
        // console.log(results?.data);
        if(statusCode === 403){
          userLogOut();
          console.log(`${results}`);
          return;
        }

        if(!Array.isArray(results?.data)){
          setData((prevData) => ({
            ...prevData,
            currentPage: pageToQuery,
            resultsCount: results.totalCount,
            pageCount: results.totalPageCount,
            // countOfFilteredProjects: results.totalCount,
            list: []
          }));
          setShowNoDataText(true);
          return;
        }

        setShowNoDataText(false);
        setData((prevData) => ({
          ...prevData,
          currentPage: pageToQuery,
          resultsCount: results.totalCount,
          pageCount: results.totalPageCount,
          // countOfFilteredProjects: results.totalCount,
          list: results?.data?.map((oppo) => {
            return {
              ID: oppo.notice_id,
              Title: oppo.title,
              Source: oppo.link,
              Feedback: oppo.feedback,
              Funding_Entity: oppo.department,
              Issued_Date: new Date(oppo.published_date).toLocaleDateString('en-US'),
              Expired_Date: oppo?.expired_date === "No Data"
                              ? "N/A"
                              : new Date(oppo.expired_date).toLocaleDateString('en-US'),
              Wiser_Score: oppo.composite_score && (oppo.composite_score*100).toFixed(0),
            }
          })
        }));
      })
      .catch((err) =>
        console.error(`Error Occurred during fetch: ${err.message}`)
      )
      .finally(() => setLoading(false));
  };

  const handleFeedback = (feedback) => {

    const dataString = JSON.stringify(feedback);

    sendOpportunityFeedback(dataString, currentUser)
      .then(({results, statusCode}) => {
        if(statusCode === 403){
          userLogOut();
          console.log(`${results}`);
          return;
        }
        queryFilteredData();
      })
      .catch((err) =>
        console.error(`Error Occurred during fetch: ${err.message}`)
      )
      .finally(() => setLoading(false));
  }

  const paginate = (number) => {
    queryFilteredData(number);
    scrollToListContent();
  };

  const scrollToListContent = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleSearchBarFocusCallback = (e) => {
    window.scrollTo(0, 0);
  };

  const handleBackBtnSubmit = () => {
    navigate(-1);
  };

  const FirstRecordOnPage =
    (data.currentPage - 1) * TABLE_PAGE_RECORD_COUNT + 1;
  const LastRecordOnPage = Math.min(data.currentPage * TABLE_PAGE_RECORD_COUNT, FirstRecordOnPage - 1  + data.list?.length);
  const TotalResults = data.resultsCount;

  return (
    <Layout
      isLoaderDisplay={isLoading}
      filtersAlignCenter={true}
      footerRelative={true}
      searchbarPlaceholder={SEARCHBAR_PLACEHOLDER}
      handleSearchBarFocusCallback={handleSearchBarFocusCallback}
      handleSearchSubmit={queryFilteredData}
      handleSearchInput={(e) => setSearchText(e.target.value)}
      onBackClickCallback={handleBackBtnSubmit}
      filterOptions={options}
      filtersSelected={filters}
      handleClickFilters={setFilterByKey}
      handleResetFilters={resetFilters}
    >
      {data.pageCount > 0 && (
        <TopScrollBtn
          color="#2D6291"
          label={"To Top"}
          handleOnClick={scrollToListContent}
        />
      )}
      {data.list?.length > 0 ? (
        <>
          <TableContainer style={{ margin: '4% auto 0' }}>
            <div style={{ width: "-webkit-fill-available", marginLeft: "5%", color: '#6C7073', fontStyle: 'italic' }}>
              {`Showing ${FirstRecordOnPage} - ${LastRecordOnPage} of ${TotalResults} results`}
            </div>
            <TableComponent
              tableData={data.list}
              handleFeedback={handleFeedback}
            />
          </TableContainer>
          <Pagination
            numberOfPages={data.pageCount}
            currentPage={data.currentPage}
            paginate={paginate}
          />
        </>
      ) : (
        <>
          {
            (showNoDataText) && (
              <h1 style={{ color: "#827988", width: "100%", textAlign: "center", marginTop: '4%' }}>
                No data Present at the moment
              </h1>
            )
          }
        </>
      )}
    </Layout>
  );
}

export default Opportunities;
