import Header from "./Header/header";
import Footer from "./Footer/footer";
import Breadcrumb from "./Breadcrumb/breadcrumb";
import Loader from "./Loader/loader";
import { LINK } from "../services/Constants/constant";
import {
    LayoutProvider,
    BodyContainer,
    BreadcrumbWrapper,
    TextButton,
    TitleContainer,
} from "./style";

import FiltersRow from "./Filters/FiltersRow";
import { RoundedButton, SearchBar } from "components";


const Layout = (props) => {
    const TITLE = "Opportunities";

    return (
        <LayoutProvider>
            <Loader isLoaderDisplay={props.isLoaderDisplay} />
            
            <Header
                isHeaderFixed={false}
                onBackClickCallback={props.onBackClickCallback}
            />
            <BodyContainer>
                <BreadcrumbWrapper>
                    <Breadcrumb />
                    <TextButton href={`${LINK}`}>Share Additional Data</TextButton>
                </BreadcrumbWrapper>
                <div style={{ display: 'flex', justifyContent: 'space-between'  }}>
                    <TitleContainer>{props.title || TITLE}</TitleContainer>
                    {/* {
                        props.onContactButtonClick && (
                            <RoundedButton
                                height={"4.2vh"}
                                textColor={"white"}
                                backgroundColor={"#2D6291"}
                                borderRadius={"10px"}
                                handleClick={props.onContactButtonClick}
                            >
                                Contact Us
                            </RoundedButton>
                        )
                    } */}
                </div>
                {/* Plan is to define filter component in the Layout section and pass all the data from the page.
                In this project I am reducing logical component and making most of the component as UI component*/}
                <center>
                    {
                        props.handleSearchInput && (
                            <SearchBar
                                width={'60vw'}
                                height={'4.5vh'}
                                fontSize={'1rem'}
                                placeholder={props.searchbarPlaceholder}
                                handleSearchInput={props.handleSearchInput}
                                submitCallback={props.handleSearchSubmit}
                                handleSearchFocusCallback={props.handleSearchBarFocusCallback}
                            />
                        )
                    }
                    <br/>
                    {
                        props.filtersSelected && (
                            <FiltersRow
                                alignCenter={props.filtersAlignCenter}
                                filtersState={props.filtersSelected}
                                filterOptions={props.filterOptions}
                                handleClickFilters={props.handleClickFilters}
                                handleResetFilters={props.handleResetFilters}
                                isSingleSelect={props.singleSelect}
                            />
                        )
                    }
                </center>
                {props.children}
            </BodyContainer>
            <Footer relative={props.footerRelative} />
        </LayoutProvider>
    );
};

export default Layout;
