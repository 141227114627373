import { media } from "assets/static";
import styled from "styled-components";

export const ContentContainer = styled.div`
    ${'' /* padding: 0 1.56vw; */}
    padding: ${props => props.isHeaderCollapsed ? "20vh 1.56vw 0 1.56vw" : "0 1.56vw" };
    height: 100%;
    width: 90vw;
    margin: ${props => props.margin || '3%  auto 0px'};
    min-height: ${props => props.isHeaderCollapsed ? "67vh" : "" };
    display: flex;
    flex-direction: column;

    ${media.desktop} {
        width: 90vw;
    }

    ${media.laptop} {
        width: 86vw;
    }
`