import { Breadcrumb, Link, Typography } from '../style';
import withRouter from '../../services/Utils/ComponentWrapper';
import { TrademarkLogo } from 'pages/Auth/styles/style.Auth.Screen';
import { useNavigate } from 'react-router';

const getDisplayName = (input_pathNames) => {
    // Cater for the path of list page
    if (input_pathNames.length >= 2 && input_pathNames[1] === "list") {
        input_pathNames[1] += " of all ";
        switch (input_pathNames[0]) {
            case "clinicalTrials":
                input_pathNames[1] += "Trials";
                break;
            case "vaccineAvailability":
                input_pathNames[1] += "Vaccines";
                break;
            case "funding":
                input_pathNames[1] += "Projects";
                break;
            default:
                break;
        }
    }
   
}

const Breadcrumbs = (props) => {
    const navigate = useNavigate();
    const histories = props.history;
    // const pathname = props.location.pathname;
    // const displayName = getDisplayName(pathname.split('/').filter(x => x));
    const getDispName = (path) => {
        switch(path){
            case 'WiserMatches': return 'WISE-R Matches';
            case 'contact-us': return 'Contact Us';
            default: return path;
        }
    }

    const paths = () => {
        const pathnames = props.location.pathname.split('/').filter(x => x);
        let routes = [];
        let routesIndex = 0;
        pathnames.map((path, index) => {
            if(index === pathnames.indexOf('WiserMatches')-1){
                return null;
            }

            routes[routesIndex] = [getDispName(path), `/${pathnames.slice(0, index + 1).join('/')}`]
            routesIndex++;
        })
        return routes;
    }
    const pathNames = paths();

    return (
        pathNames.length > 0
            ? <Breadcrumb>
                <Typography 
                    key={'ABL WISE-R'}
                    >
                        {'ABL WISE-R'}&nbsp;<TrademarkLogo size={`12px`}/>&nbsp;{' >'}
                </Typography>
                <Link onClick={() => histories(`/`)}>Homepage</Link>
                {
                    pathNames.map(([name, route], index) => {
                        const routeTo = route;
                        const lastIndex = pathNames.length - 1 === index;
                        let dispName= name;
                        const goBack = (-1) * (pathNames.length -1 - index);
                        // const tempName = name;
                        
                        // let dispName = tempName.join(" ");
                        let modifiedTempName = dispName.split('%20');
                        dispName = modifiedTempName.join(" ");
                        modifiedTempName = dispName.split('%');
                        dispName = modifiedTempName.join("");

                        return (
                            lastIndex ?
                                <Typography key={index}>{dispName[0].toUpperCase() + dispName.substring(1)}</Typography>
                                :
                                <Link key={index} onClick={() => navigate(goBack)}>
                                    {dispName[0].toUpperCase() + dispName.substring(1)}
                                </Link>
                        );
                    })
                }
            </Breadcrumb>
        : <div></div>
    );
}

export default withRouter(Breadcrumbs);