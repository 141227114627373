import React from "react";
import Layout from "layout/opportunitiesLayout";
import { Timeline, TopScrollBtn } from "components";
import { useEffect } from "react";
import { useState } from "react";
import { useAuth } from "contexts/AuthContext";
import parse from "html-react-parser";

import {
  CardSummaryStatus as SummaryStatus,
  CardSummaryStatusText as SummaryStatusText,
  CardTrialID as TrialID,
  HeaderRow,
  HeaderRowLastUpdatedOn,
  HeaderRowTrialTitle,
  HeadlineContainer,
  CardTitle,
  CardBody,
  RowWrapper,
  RowProperty,
  RowValue,
  DetailsCardContainer,
  DetailsCardRow,
  DetailsCard,
  DetailsCardColumn,
} from "../ClinicalTrials/style.clinicalTrails";
import { useNavigate, useParams } from "react-router";
import { getStatusColor } from "../ClinicalTrials/utils/util";
import { queryOpportunityData } from "./utils/requests";
import { CardNoData } from "components/Cards/style.cards";
import { opportunityStatusColor } from "services/Constants/constant.js";
import DownloadItem from "components/Download/DownloadItem";

function OpportunityDetails(props) {
  const [isLoading, setLoading] = useState(false);
  const { currentUser, userLogOut } = useAuth();
  const navigate = useNavigate();
  const { opportunityId } = useParams();

  const [expandedKeys, setExpandedKeys] = useState([]);

  const TITLES = {
    Contract_Opportunity: "Contract Opportunity",
    Classification: "Classification",
    GeneralInformation: "General Information",
    Diseases: "Diseases",
    Technologies: "Technologies",
    Description: "Description",
    Attachment: "Attachment/links",
    Contact_Information: "Contact Information",
    Timeline: "Timeline",
  };
  const TEXT_to_top = "To Top";
  const TEXT_last_updated_on = "Last updated on ";
  const TEXT_no_attachment =
    "No Attachments/Links associated with Contract Opportunity";
  const TEXT_no_timeline = "Timeline not available";
  const STRING_LENGTH_LIMIT = 500;

  const [data, setData] = useState({
    Title: "",
    Status: "Unavailable",
    Last_Updated: "",
    Data: {
      Contract_Opportunity: {
        NoticeID: "",
        Department_Ind_Agency: "",
        Sub_Tier: "",
        Office: "",
      },
      Classification: {
        Original_Set_Aside: "",
        Product_Service_Code: "",
        NAICS_Code: "",
        Place_of_Performance: "",
      },
      General_Information: {
        Contract_Opportunity_Type: ")",
        Updated_Published_Date: "",
        Original_Published_Date: "",
        Updated_Date_Offers_Due: "",
        Original_Date_Offers_Due: "",
        Budget_Start_Date: "",
        Budget_End_Date: "",
        Inactive_Policy: "",
        Updated_Inactive_Date: "",
        Original_Inactive_Date: "",
        Initiative: "",
        Principle_Investigator: "",
      },
      Attachment: [],
      Contact_Information: {
        Contracting_Office_Address: "",
        Primary_Contact: "",
        Secondary_Contact: "",
      },
      Timeline: [],
      Description: "",
      Technology: [],
      Disease: []
    },
  });

  const fetchCall = () => {
    setLoading(true);

    queryOpportunityData(opportunityId, currentUser)
      .then(({results, statusCode}) => {
        
        if(statusCode === 403){
          userLogOut();
          console.log(`${results}`);
          return;
        }
        
        setData((prevData) => ({
          ...prevData,
          Title: results.Title,
          Status: results.Status,
          Last_Updated: results.Last_Updated,
          Data: results.Data,
        }));
        // console.log(results)
      })
      .catch((err) =>
        console.error(`Error Occurred during fetch: ${err.message}`)
      )
      .finally(() => setLoading(false));
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchCall();
  }, []);

  const updateExpandedKeys = (element) => {
    if (expandedKeys.includes(element)) {
      setExpandedKeys((prev) => prev.filter((key) => key !== element));
    } else {
      setExpandedKeys((prev) => [...prev, element]);
    }
  };

  const formatString = (input) => {
    return input
      .replace(/[\n\t]+/g, '<br/>')
      .replace(/\s+/g, ' ')   // Replace multiple spaces with a single space
      .trim();                // Remove leading and trailing spaces
  };  
  
  const generateRows = (obj) => {
    return obj ? (
      <>
        {Object.entries(obj)?.map((entry) => {
          // console.log(entry)
          const value = Array.isArray(entry[1])
            ? entry[1].length > 0
              ? entry[1].join(", ")
              : "-"
            : entry[1] != ""
            ? entry[1]
            : "-";

          // console.log(value)

          return (
            <RowWrapper key={entry[0]}>
              <RowProperty>{entry[0].replaceAll("_", " ") + ": "}</RowProperty>
              <RowValue>
                {value.length > STRING_LENGTH_LIMIT
                  ? expandedKeys.includes(entry[0])
                    ? value
                    : value.substring(0, STRING_LENGTH_LIMIT)
                  : value}
              </RowValue>
              {value.length > STRING_LENGTH_LIMIT && (
                <span
                  style={{ cursor: "pointer", color: "blue" }}
                  onClick={() => updateExpandedKeys(entry[0])}
                >
                  {expandedKeys.includes(entry[0])
                    ? " (...Show less)"
                    : " (...Show more)"}
                </span>
              )}
            </RowWrapper>
          );
        })}
      </>
    ) : null;
  };

  const [statusColor, statusTextColor] = opportunityStatusColor[data.Status];

  return (
    <Layout
      isLoaderDisplay={isLoading}
      footerRelative={true}
      // headerOrder={"back-button | abl-logo | username-button"}
      onBackClickCallback={() => navigate(-1)}
    >
      <HeadlineContainer>
        <HeaderRow>
          <HeaderRowTrialTitle>{data.Title}</HeaderRowTrialTitle>
          <SummaryStatus statusColor={statusColor}>
            <SummaryStatusText color={statusTextColor}>
              {data.Status}
            </SummaryStatusText>
          </SummaryStatus>
        </HeaderRow>
      </HeadlineContainer>

      <DetailsCardContainer>
        <DetailsCardRow>
          <DetailsCardColumn style={{ width: "105%" }}>
            <DetailsCard>
              {/* Summary */}
              <CardTitle>{TITLES.Contract_Opportunity}</CardTitle>
              <CardBody>
                {generateRows(data.Data.Contract_Opportunity)}
              </CardBody>
            </DetailsCard>
            <DetailsCard>
              {/* Summary */}
              <CardTitle>{TITLES.Classification}</CardTitle>
              <CardBody>{generateRows(data.Data.Classification)}</CardBody>
            </DetailsCard>
          </DetailsCardColumn>

          <DetailsCard>
            {/* General Information */}
            <CardTitle>{TITLES.GeneralInformation}</CardTitle>
            <CardBody>{generateRows(data.Data.General_Information)}</CardBody>
          </DetailsCard>
        </DetailsCardRow>

        <DetailsCardRow>
          {/* <DetailsCardColumn> */}
          <DetailsCard>
            <CardTitle>{TITLES.Diseases}</CardTitle>
            <CardBody>
              { 
                data.Data?.Disease?.Disease 
                  ? data.Data.Disease.Disease.join(", ") 
                  : null
              }
            </CardBody>
          </DetailsCard>
          {/* </DetailsCardColumn> */}
          <DetailsCard>
            <CardTitle>{TITLES.Technologies}</CardTitle>
            {/* <CardBody>{generateRows(data.Data.Technology)}</CardBody> */}
            <CardBody>
              { 
                data.Data?.Technology?.Technology 
                  ? data.Data.Technology.Technology.join(", ") 
                  : null
              }
            </CardBody>
          </DetailsCard>
        </DetailsCardRow>

        <DetailsCardRow>
          <DetailsCard>
            <CardTitle>{TITLES.Description}</CardTitle>
            <CardBody>
            <p>
              {parse(
                data.Data.Description.length > STRING_LENGTH_LIMIT
                ? expandedKeys.includes('Description')
                  ? formatString(data.Data.Description)
                  : formatString(data.Data.Description.substring(0, STRING_LENGTH_LIMIT))
                : formatString(data.Data.Description)
              )}
              </p>
              {data.Data.Description.length > STRING_LENGTH_LIMIT && (
                <span
                  style={{ cursor: "pointer", color: "blue" }}
                  onClick={() => updateExpandedKeys('Description')}
                >
                  {expandedKeys.includes('Description')
                    ? " (...Show less)"
                    : " (...Show more)"}
                </span>
              )}
            </CardBody>
          </DetailsCard>
        </DetailsCardRow>

        {/* <DetailsCardRow>
                    <DetailsCard
                        width={'50%'}>
                        <CardTitle>
                            {TITLES.Attachment}
                        </CardTitle>
                        <CardBody style={{ display: 'flex', height: '100%' }}>
                        {
                            data.Data.Attachment.length > 0 
                            ? <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '20px' }}>
                                {
                                    Object.entries(data.Data.Attachment).map((entry, index) => {
                                        const name = entry[1][0];
                                        const size = entry[1][1];
                                        const link = entry[1][2];

                                        return <DownloadItem 
                                                    key={index}
                                                    name={name}
                                                    size={size}
                                                    link={link}
                                                    handleOnClick={() => window.open(link)}
                                                />
                                    })
                                }
                            </div>
                            : <CardNoData style={{ fontSize: '0.8rem' }}>{TEXT_no_attachment}</CardNoData>
                        }
                            
                        </CardBody>
                    </DetailsCard>
                    <DetailsCard>
                        <CardTitle>
                            {TITLES.Contact_Information}
                        </CardTitle>
                        <CardBody style={{ display: 'flex', gap: '20px' }}>
                            {
                                Object.entries(data.Data.Contact_Information).map((item, index) => {
                                    return (
                                        <div style={{ lineHeight: '1.5em' }}>
                                            <div 
                                                style={{ fontSize: '0.666rem', fontWeight: '500' }}>
                                                    {item[0].replaceAll('_', ' ') + ":"}
                                            </div>
                                            <span style={{ color: '#2D6291' }}>
                                                {item[1]}
                                            </span>
                                        </div>
                                    )
                                })
                            }
                        </CardBody>
                    </DetailsCard>
                </DetailsCardRow>

                <DetailsCardRow>
                    <DetailsCard>
                        <CardTitle>
                            {TITLES.Timeline}
                        </CardTitle>
                        <CardBody>
                        {
                            data.Data.Timeline.length > 0 
                            ? <Timeline data={data.Data.Timeline} />
                            : <CardNoData style={{ fontSize: '0.8rem' }}>{TEXT_no_timeline}</CardNoData>
                        }
                        </CardBody>
                    </DetailsCard>
                </DetailsCardRow>  */}
      </DetailsCardContainer>

      <TopScrollBtn label={TEXT_to_top} handleOnClick={scrollToTop} />
    </Layout>
  );
}

export default OpportunityDetails;
