import { IoChevronBack } from "react-icons/io5";
import { HeaderBackBtnContainer } from './style.button';

const HeaderBackButton = (props) => {
    return (
        <HeaderBackBtnContainer
            width={props.width}
            margin={props.margin}
            fontSize={props.fontSize}
            gap={props.gap}
            color={props.color}
            onClick={props.OnClickCallback}
        >
            <span><IoChevronBack size={`20px`}/></span>
            <span>Back</span>
        </HeaderBackBtnContainer>
    );
}

export default HeaderBackButton;