import React from 'react';
import { Ratings, Tags } from "components";
import { 
    CardContentWrapper,
    CardContent,
    OrganisationCard,
    OtherTagsWrapper,
    LocationWrapper,
    CardTitle,
    Type,
    Description,
    PICardBody,
    FieldContainer,
    Field,
    FieldLabel,
    ScoreContainer,
    TitleContainer
} from "./style.components";
import LearnMore from "./LearnMore";

export default function OrganizationCard(item, index) {
    if(item.Type === 'Company') return getCompanyTile(item, index);
    if(item.Type === 'PI') return getPITile(item, index);
    else return;
}

const getCompanyTile = (item, index) => {
    return (
        <OrganisationCard key={index + '_' + item.Company_Name}>
            <CardContentWrapper>
                <TitleContainer>
                    <CardTitle id={"Company Name- " + index}>
                        {item["Company_Name"]}
                    </CardTitle>
                    <ScoreContainer>{`${item['Match_Score']}/100`}</ScoreContainer>
                </TitleContainer>
                <Type>
                    {'Pharmaceuticals: ' + item["Pharmaceuticals"].join(", ") ?? <span>&nbsp;</span>}
                </Type>
                <Type>
                    <span>{'Company Match: ' }</span>
                    &nbsp;
                    -
                    {/* <Ratings /> */}
                </Type>
                <Description>
                    <CardContent
                        id={"Card Content- " + index}>
                        {item["Description"]}
                    </CardContent>
                </Description>
            </CardContentWrapper>
            
            <LocationWrapper>
                { getLocationTags(item.Locations) }
            </LocationWrapper>
            
            <OtherTagsWrapper>
                { getOtherTags(item) }
            </OtherTagsWrapper>
            
            <div style={{ width: '100%', display: 'flex', gap: '.5vw'}}>
                <LearnMore item={item} />
            </div>
        </OrganisationCard>
    )
}

const linksClickHandler = (tag, value) => {
    if(value===undefined || value===null) return;
    if(tag==='PI_Email') return () => window.open(`mailto:${value}`);
    if(tag==='PI_Profile_Link') return () => window.open(`${value}`);
    else return;
}

const getPITile = (item, index) => {
    const fieldList = ['PI_Position', 'PI_Phone', 'PI_Email', 'PI_Profile_Link', 'PI_Awards_Count'];
    
    return (
        <OrganisationCard key={index + '_' + item.PI_Name} style={{height: '-webkit-fill-available'}}>
            <TitleContainer>
                <CardTitle id={"PI_Name- " + item.PI_Name} style={{margin:'0'}}>
                    {item["PI_Name"]}
                </CardTitle>
                <ScoreContainer>{`${item['Match_Score']}/100`}</ScoreContainer>
            </TitleContainer>
            <Type>{'Institution: ' + (item["PI_Institution"] ?? '-') ?? <span>&nbsp;</span>}</Type>

            <PICardBody>
                <FieldContainer>
                    <FieldLabel>{fieldList[0].slice(3)}</FieldLabel>
                    <Field>{item[fieldList[0]] ?? 'N/A'}</Field>
                </FieldContainer>
                <FieldContainer>
                    <FieldLabel>{fieldList[1].slice(3)}</FieldLabel>
                    <Field>{item[fieldList[1]] ?? 'N/A'}</Field>
                </FieldContainer>
                <FieldContainer>
                    <FieldLabel>{fieldList[2].slice(3)}</FieldLabel>
                    <Field
                        type={item[fieldList[2]] && 'link'}
                        title={'Click to open email client'}
                        onClick={linksClickHandler(fieldList[2], item[fieldList[2]])}>
                            {item[fieldList[2]] ?? 'N/A'}
                    </Field>
                </FieldContainer>
                <FieldContainer>
                    <FieldLabel>{fieldList[3].slice(3).replace('_',' ')}</FieldLabel>
                    <Field 
                        type={item[fieldList[3]] && 'link'}
                        title={'Click to open LinkedIn profile'}
                        onClick={linksClickHandler(fieldList[3], item[fieldList[3]])}
                        >
                            {item[fieldList[3]] ? 'LinkedIn': 'N/A'}
                    </Field>
                </FieldContainer>
                <FieldContainer>
                    <FieldLabel>{fieldList[4].slice(3).replace('_',' ')}</FieldLabel>
                    <Field>{item[fieldList[4]] ?? 'N/A'}</Field>
                </FieldContainer>
            </PICardBody>

            <div style={{ width: '100%', display: 'flex', gap: '.5vw'}}>
                <LearnMore item={item} disabled={true} />
            </div>
        </OrganisationCard>
    )
}

const getLocationTags = (locations) => {
    if(Array.isArray(locations)){
        return locations.map((tagItem, index) => {
            return <Tags key={index} data={tagItem} tagsKey={index === 0 && 'Location'}/>;
        });
    }
    else if(typeof locations === 'object'){
        if (locations == null) return null;

        let locationString = "";
        if (locations.city) locationString += locations.city;
        if (locations.state) locationString += `, ${locations.state}`;

        return <Tags
                data={locationString}
                tagsKey={"Location"}
            />;
    }
    
}

const getOtherTags = (item) => {
    const tagsList = getOtherTagsList(item);
    return tagsList.map((tagItem, index) => {
        return <Tags key={index} data={tagItem[0]} tagsKey={tagItem[1]}/>;
    });
}

const getOtherTagsList = (item) => {
    let tagsList = [
        [item["Employee_Size"], "Employee Size"]
    ];
    let cardSpace = 3;
    if (cardSpace > 0 && item["Total_Funding"]) {
        tagsList.push([`${item["Total_Funding"]}`, "Total Funding (USD)"]);
        cardSpace -= 1;
    }
    if (cardSpace > 0 && item["Count_of_Publications"]) {
        tagsList.push([`${item["Count_of_Publications"]}`, "# of Publications"]);
        cardSpace -= 1;
    }
    return tagsList;
}