import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { ROUTE_LOGIN } from "services/Constants/constant";

export const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const userSignIn = (userInfo, rememberToken) => {
    localStorage.setItem("user", JSON.stringify(userInfo));
    setCurrentUser(userInfo);
  };

  const userLogOut = (showAlertPrompt = true) => {
    if (localStorage.getItem("user")){ 
      localStorage.removeItem("user");
      navigate(ROUTE_LOGIN);
      if(showAlertPrompt)
        alert("Session timeout! Please login again.");
    }
    setCurrentUser();
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user") !== 'undefined' ? localStorage.getItem("user") : null);
    if(user=== null){
      navigate(ROUTE_LOGIN);
      alert("Session timeout! Please login again.");
    }
    setCurrentUser(user);
    setLoading(false);
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser, userSignIn, userLogOut }}>
      {/* Don't render children until loading is done */}
      {!loading && children}
    </AuthContext.Provider>
  );
}

