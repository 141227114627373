import { 
    ComponentTablePagination, 
    GraphGrid, 
    GridCard, 
    RoundedButton, 
    TableContainer 
} from 'components'
import React, { useState } from 'react'
import { ContactContainer, TotalStatContainer } from './style.organization'
import { useNavigate } from 'react-router';

function GraphUI(props) {

    const INFO = {
		AboutCompany: ``,
		NonDilutiveFundingSuccess: '',
        FundingSuccessAcrossTechnologiesAndDiseases: '',
        Publications: ''
	};

	const TITLES = {
		AboutCompany: `About`,
		NonDilutiveFundingSuccess: 'Non-Dilutive Funding Success By Agency',
        FundingSuccessAcrossTechnologiesAndDiseases: 'Funding Success Across Technologies/Diseases',
        Publications: 'Publications'
	};

	const NODATATEXT = {
		AboutCompany: `No summary available for this company yet`,
		NonDilutiveFundingSuccess: 'Coming soon...',
        FundingSuccessAcrossTechnologiesAndDiseases: 'Coming soon...',
        Publications: 'Coming soon...'
    };

    const { about, 
            nonDilutiveFundingSuccess, 
            fundingSuccessAcrossTechnologiesAndDiseases, 
            publications, 
            totalFunding, 
            totalLicensedProducts 
        } = props.data;
    
    // const TABLE_DATA_SIZE_PUBLICATIONS = 15;
    // const TABLE_HEADERS = ["", "", ""];
    // const [ publicationsTablePageIndex, setPublicationsTablePageIndex ] = useState(1);
    // let publicationsTableStartRow = ((publicationsTablePageIndex - 1) * TABLE_DATA_SIZE_PUBLICATIONS);
    // let publicationsTableEndRow = Math.min(publications.length, (publicationsTablePageIndex*TABLE_DATA_SIZE_PUBLICATIONS));
    const navigate = useNavigate();

    const handleContactButtonClick = () => {
        navigate(`contact-us`, {state: {'organizationName': props.organizationName}})
    }

    return (
        <>
            {
                props.isVisible && (
                    <GraphGrid>
                        <GridCard
                            gridRow={`1/6`}
                            gridColumn={`1/15`}
                            info={INFO.AboutCompany}
                            isDataAvailable={about?.summary}
                            noDataText={NODATATEXT.AboutCompany}
                            title={TITLES.AboutCompany}
                        >	
                            {about?.summary?.slice(0,1200)}
                        </GridCard>
                        
                        <GridCard
                            gridRow={`6/17`}
                            gridColumn={`1/8`}
                            info={INFO.NonDilutiveFundingSuccess}
                            isDataAvailable={nonDilutiveFundingSuccess}
                            noDataText={NODATATEXT.NonDilutiveFundingSuccess}
                            title={TITLES.NonDilutiveFundingSuccess}
                        >
                            
                        </GridCard>
                        <GridCard
                            gridRow={`6/17`}
                            gridColumn={`8/15`}
                            info={INFO.FundingSuccessAcrossTechnologiesAndDiseases}
                            isDataAvailable={fundingSuccessAcrossTechnologiesAndDiseases}
                            noDataText={NODATATEXT.FundingSuccessAcrossTechnologiesAndDiseases}
                            title={TITLES.FundingSuccessAcrossTechnologiesAndDiseases}
                        >
                            
                        </GridCard>
                    

                        <GridCard
                            gridRow={`5/17`}
                            gridColumn={`15/22`}
                            info={INFO.Publications}
                            isDataAvailable={publications?.length > 0}
                            noDataText={NODATATEXT.Publications}
                            title={TITLES.Publications}
                        >
                            {/* <ComponentTablePagination
                                height={"100%"}
                                center={true}
                                alwaysShowArrows={true}
                                currentPage={publicationsTablePageIndex}
                                numberOfPage={Math.floor((publications.length)/TABLE_DATA_SIZE_PUBLICATIONS) + 1}
                                handlePageClick={(pageIndex) => setPublicationsTablePageIndex(pageIndex)}
                            >
                                <TableContainer
                                    tableHeight={"90%"}
                                    theadTextAlign={"center"}
                                    tableData={publications.slice(publicationsTableStartRow, publicationsTableEndRow)}
                                    tableHeader={TABLE_HEADERS}
                                />
                            </ComponentTablePagination> */}
                        </GridCard>
                        
                        <GridCard
                            gridRow={`1/5`}
                            gridColumn={`15/18`}
                            isBlank={true}
                            isDataAvailable={true}
                        >
                            <TotalStatContainer>
                                <div>Total Funding</div>
                                <div>{totalFunding || 0}</div>
                                <RoundedButton
                                    handleClick={() => window.open('')}
                                >
                                    More about funding
                                </RoundedButton>
                            </TotalStatContainer>
                        </GridCard>
                        <GridCard
                            gridRow={`1/5`}
                            gridColumn={`18/22`}
                            isBlank={true}
                            isDataAvailable={true}
                            style={{ 
                                color: '#468AAB',
                                backgroundColor: '#DBEAF3',
                                border: '2px solid #468AAB',
                                boxShadow: '3px 12px 20px 0px #589EC326',
                                cursor: 'pointer'
                             }}
                        >
                            <ContactContainer onClick={handleContactButtonClick}>
                                <div>Click here to view Company's Contact List</div>
                                {/* <div>{totalLicensedProducts || 0}</div> */}
                                {/* <RoundedButton
                                    handleClick={handleContactButtonClick}
                                >
                                    Search
                                </RoundedButton> */}
                            </ContactContainer>
                        </GridCard>
                    
                    </GraphGrid>
                )
            }
        </>
    )
}

export default GraphUI