import { createSlice } from '@reduxjs/toolkit';

// Default filter states
export const defaultOpportunitiesFilters = {
  Status: [],
  Published_Year: [],
  Funding_Entity: [],
  Recipient: [],
  Diseases: [],
  Technology: [],
  Organization_Type: [],
  Matches: []
};

export const defaultWiserMatchesFilters = {
  Organization_Types: [],
  Organization_Sizes: [],
};

export const defaultOrganizationFilters = {
  Diseases: [],
  Technology: [],
};

export const defaultContactsFilters = {};
// Function to get default filters based on page type
const getPagesDefaultFilterState = (page) => {
  switch (page) {
    case 'Opportunities': return defaultOpportunitiesFilters;
    case 'Wiser Matches': return defaultWiserMatchesFilters;
    case 'Organization': return defaultOrganizationFilters;
    case 'Contacts': return defaultContactsFilters;
    default: return defaultOpportunitiesFilters;
  }
};

// Redux Slice
const filters = createSlice({
  name: 'filters/opportunities',
  initialState: {
    'Opportunities': defaultOpportunitiesFilters,
    'Wiser Matches': defaultWiserMatchesFilters,
    'Organization': defaultOrganizationFilters,
    'Contacts': defaultContactsFilters
  },
  reducers: {
    initPageFilters: (state, action) => {
      const { page } = action.payload;
      if (!state[page]) {
        state[page] = getPagesDefaultFilterState(page);
      }
    },
    setFilterByKey: (state, action) => {
      const { page, key, value } = action.payload;
      if (state[page]) {
        state[page][key] = value;
      }
    },
    resetFilters: (state, action) => {
      const { page } = action.payload;
      if (state[page]) {
        Object.keys(state[page]).forEach((key) => {
          state[page][key] = [];
        });
      }
    }
  }
});

// Export actions
export const { initPageFilters, setFilterByKey, resetFilters } = filters.actions;

// Export reducer
export default filters.reducer;
