import React, { useEffect } from "react";
import Layout from "layout/opportunitiesLayout";
import { useState } from "react";
import { queryOrganizationData, fetchFilters } from "../utils/requests";
import { useDispatch } from "react-redux";
import { RoundedButton, TopScrollBtn } from "components";
import { useAuth } from "contexts/AuthContext";
import List from "../components/List";
import { useLocation, useNavigate } from "react-router";
import useFilter from "../hooks/useFilter";
import GraphUI from "./GraphUI";

function Organization(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const organizationName = location?.state['Company_Name'] || '';
  
  const [isLoading, setLoading] = useState(true);
  const { currentUser, userLogOut } = useAuth();
  const [searchText, setSearchText] = useState("");
  
  const [isGraphSectionVisible, setIsGraphSectionVisible] = useState(true);

  const [data, setData] = useState({
    currentPage: 1,
    pageCount: 1,
    countOfFilteredProjects: 0,
    list: [],
    graph: {}
  });

  const {
    filters,
    setFilterByKey,
    resetFilters,
    options,
    setOptionsWithResults,
  } = useFilter('Organization');

  useEffect(() => {
    queryFilteredData();
  }, [filters]);

  const queryFilteredData = (newPage = data.currentPage) => {
    const pageToQuery = newPage == data.currentPage ? 1 : newPage;
    setLoading(true);

    let filtersData = JSON.parse(JSON.stringify(filters));
    filtersData["Search"] = searchText;
    // filtersData["organizationName"] = organizationName;
    const temp = {
      'organizationName': organizationName,
      'newFilters': filtersData
    };
    
    const dataString = JSON.stringify({
      filters: filtersData,
      pageNum: pageToQuery,
    });

    const dataString2 = JSON.stringify({
      filters: temp,
      pageNum: pageToQuery,
    });

    fetchFilters(dataString, currentUser, userLogOut)
      .then((results) => {
        setOptionsWithResults(results);
      })
      .catch((err) =>
        console.error(`Error Occurred during fetch: ${err.message}`)
      )
      .finally(() => setLoading(false));

    queryOrganizationData(dataString2, currentUser)
      .then(({results, statusCode}) => {
        if(statusCode === 403){
          userLogOut();
          console.log(`${results}`);
          return;
        }
        
        setData((prevData) => ({
          ...prevData,
          pageCount: results.totalPageCount,
          countOfFilteredProjects: results.totalCount,
          list: results.list,
          currentPage: pageToQuery,
          graph: {
            about: {
              companyName: organizationName,
              summary: results.orgData.description,
              websiteUrl: results.orgData.website,
              linkedInUrl: results.orgData.linkedin,
              email: 'abc@sanofi.com'
            }, 
            nonDilutiveFundingSuccess: {}, 
            fundingSuccessAcrossTechnologiesAndDiseases: {},
            publications: [ {} ], 
            totalFunding: results.orgData.totalFunding?.toLocaleString('en-US'), 
            totalLicensedProducts: 0 
          }
        }));
      })
      .catch((err) =>
        console.error(`Error Occurred during fetch: ${err.message}`)
      )
      .finally(() => setLoading(false));
  };

  const paginate = (number) => {
    queryFilteredData(number);
    scrollToListContent();
  };

  const scrollToListContent = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleBackBtnSubmit = () => {
    if (isGraphSectionVisible) {
      navigate(-1);
    } else {
      setIsGraphSectionVisible(true);
    }
  };

  return (
    <Layout
      title={organizationName || 'Organization'}
      isLoaderDisplay={isLoading}
      footerRelative={true}
      // onContactButtonClick={handleContactButtonClick}
      onBackClickCallback={handleBackBtnSubmit}
      filterOptions={options}
      filtersSelected={filters}
      handleClickFilters={setFilterByKey}
      handleResetFilters={resetFilters}
    >
      {data.pageCount > 0 && (
        <TopScrollBtn
          color="#2D6291"
          label={"To Top"}
          handleOnClick={scrollToListContent}
        />
      )}
      <GraphUI 
        isVisible={isGraphSectionVisible}
        data={data.graph} 
        organizationName={organizationName}
      />
      <List data={data} paginate={paginate} />
    </Layout>
  );
}

export default Organization;
