import React from "react";
import { ContactTable, ContactTableTbody, Table, TableWrapper, Tbody, TdDiv, ThDiv, Thead, TitleTd } from "./style.components";

function ContactTableComponent({ tableData }) {
  const TABLE_HEADERS = [
    "Company Name",
    "Name",
    "Department",
    "Seniority",
    "Email",
    "Phone Number",
    "LinkedIn",
  ];
  const TABLE_COLUMN_WIDTHS = ["12%", "12%", "12%", "12%", "15%", "12%", "15%"];

  return (
    <TableWrapper>
      <ContactTable>
        <Thead>
          <tr>
            {TABLE_HEADERS.map((header, index) => {
              return (
                <th
                  key={"Table_Header_" + header}
                  width={TABLE_COLUMN_WIDTHS[index]}
                >
                  <ThDiv>
                    <span>{header}</span>
                  </ThDiv>
                </th>
              );
            })}
          </tr>
        </Thead>
        <ContactTableTbody>
          <TableBody tableData={tableData} />
        </ContactTableTbody>
      </ContactTable>
    </TableWrapper>
  );
}

const renderColumn = (name, value) => {
    if(value=='N/A')
        return <TdDiv>{value}</TdDiv>

    switch(name){
        case 'Email': return (<TitleTd 
                                title={'Click to compose email'}
                                textAlign={'center'}
                                onClick={()=> window.open(`mailto:${value}`)}
                                >
                                    {value}
                                </TitleTd>);
        case 'LinkedIn': return (<TitleTd 
                                    title={'Click to open this link'}
                                    textAlign={'center'}
                                    onClick={()=> window.open(`${value}`)}
                                    >
                                        {value}
                                    </TitleTd>);
        default: return <TdDiv>{value}</TdDiv>
    }
}

const TableBody = ({ tableData }) => {
  return tableData.length > 0 ? (
    tableData.map((data, index) => {
      return (
        <tr key={index + "_" + data["ContactName"]}>
            {
                Object.entries(data).map(([columnName, columnData], index) => {
                    return (
                        <td>
                            {
                                renderColumn(columnName, columnData)
                            }
                        </td>
                    )
                })
            }
        </tr>
      );
    })
  ) : (
    <></>
  );
};

export default ContactTableComponent;
