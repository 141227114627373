import React, { useRef, useState } from "react";
import { RatingsWrapper } from "./style.ratings";
import { Rating } from "react-simple-star-rating";
import { RATING_DEFAULT_COMMENTS } from "services/Constants/constant";

function Ratings({ opportunityID, initialValue, handleFeedback }) {

  const [rating, setRating] = useState(initialValue?.stars || 0);
  const messageRef = useRef(initialValue?.message || "");

  const [hoverValue, setHoverValue] = useState(0);

  const handleMouseOverStar = (value) => {
    setHoverValue(value);
  };

  const handleRatingsClick = (feedback) => {
    if (feedback?.stars === 0) {
      setRating(0);
      messageRef.current = "";
      return;
    }
    setHoverValue(0);
    setRating(feedback?.stars);
    messageRef.current = feedback?.message;
    handleFeedback(feedback);
  };


  return (
    <RatingsWrapper
      key={opportunityID}
      data-tooltip={
        hoverValue === initialValue?.stars
          ? initialValue?.message
          : RATING_DEFAULT_COMMENTS[hoverValue - 1]
      }
    >
      <Rating
        initialValue={rating}
        onClick={(rating) =>
          handleRatingsClick({
            noticeId: opportunityID,
            stars: rating,
            message: RATING_DEFAULT_COMMENTS[rating - 1],
          })
        }
        onPointerMove={(e) => handleMouseOverStar(e, rating)}
        size={"1.5em"}
        transition
        fillColor={"#274A6A"}
        emptyColor={"#FFFFFF"}
        SVGstrokeColor={"#274A6A"}
        SVGstorkeWidth={"1px"}
        allowTitleTag={false}
      />
    </RatingsWrapper>
  );
}

export default Ratings;
