import React from "react";
import {
  ListInfoWrapper,
  ShowResults,
  ContentContainer,
  FlexContainer,
} from "../Opportunities/style.opportunities";
import { Pagination } from "../../../components/index.js";
import ListItem from "./ListItem.js";

const List = (props) => {
  const generateTrialCards = () => {
    return props.data?.list?.length > 0 ? (
      props.data.list.map((item, index) => {
        const cardData = {
          ID: item._id,
          NoticeID: item.notice_id,
          StatusText: item.active,
          fundingText: item.funded == "No" ? "NOT FUNDED" : "FUNDED",
          Title: item.title,
          Summary: item.description,
          ProjectEndDate: item.project_end_date ?? "-",
          PublishedDate: item.published_date ?? "-",
          DepartmentIndAgency: item.department ?? "-",
          SubTier: item.sub_tier ?? "-",
          FundingAwarded: item.amount ?? "-",
          NoticeType: item.notice_type ?? "-",
          Diseases: Array.isArray(item.disease) ? item.disease.join(", ") : "-",
          Technology: Array.isArray(item.technology) ? item.technology.join(", ") : "-",
          Locations: item.location
            ? `${item.location.city ? item.location.city + "," : ""} 
						${item.location.state ? item.location.state + "," : ""} 
						${item.location.country ? item.location.country : ""}`
            : "-",
          Link: item.link,
        };

        return <ListItem data={cardData} />;
      })
    ) : (
      <h1>No Opportunities Returned for the Given Filters</h1>
    );
  };

  return (
    <ContentContainer>
      <FlexContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {props.data.list.length > 0 ? (
            <ListInfoWrapper>
              <ShowResults>
                {`Show ${(props.data.currentPage - 1) * 20 + 1} - 
									${Math.min(props.data.countOfFilteredProjects, props.data.currentPage * 20)} of 
									${props.data.countOfFilteredProjects} Opportunities
								`}
              </ShowResults>
              <div>Last updated on 04/01/2024</div>
            </ListInfoWrapper>
          ) : (
            <ShowResults>{`Show 0 - 0 of 0 Opportunities`}</ShowResults>
          )}
          <main>{generateTrialCards()}</main>
        </div>

        <Pagination
          numberOfPages={props.data.pageCount}
          currentPage={props.data.currentPage}
          paginate={props.paginate}
        />
      </FlexContainer>
    </ContentContainer>
  );
};

export default List;
