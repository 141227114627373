import React, { useEffect } from "react";
import Layout from "layout/opportunitiesLayout";
import { useState } from "react";
import { fetchWiserMatchesFilters, queryWiserMatches } from "../utils/requests";
import { Pagination, TopScrollBtn } from "components";
import { useAuth } from "contexts/AuthContext";
import { useLocation, useNavigate } from "react-router";
import useFilter from "../hooks/useFilter";
import { CardContainer } from "./style.wisermatches";
import OrganizationCard from "../components/OrganizationCard";
import { ContentContainer } from "../style";
import { numFormatter } from "services/Function/number";

function WiserMatches(props) {
    const SEARCHBAR_PLACEHOLDER = "Search by Organizations name or Keyword";
    const LIST_PAGE_RECORD_COUNT = 20;

    const { ID } = useLocation().state;
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(true);
    const { currentUser, userLogOut } = useAuth();
    const [searchText, setSearchText] = useState("");

    const [data, setData] = useState({
        currentPage: 1,
        pageCount: 1,
        list: [],
    });

    const {
        filters,
        setFilterByKey,
        resetFilters,
        options,
        setOptionsWithResults,
    } = useFilter('Wiser Matches');

    useEffect(() => {
        queryFilteredData();
    }, [filters]);

    const queryFilteredData = (newPage = data.currentPage) => {
        const pageToQuery = newPage == data.currentPage ? 1 : newPage;
        setLoading(true);

        let filtersData = JSON.parse(JSON.stringify(filters));
        filtersData["search"] = searchText;

        const dataString = JSON.stringify({
        filters: filtersData,
        pageNum: pageToQuery,
        });
        // TODO: Un-comment when filters available from backend
    fetchWiserMatchesFilters(dataString, currentUser)
        .then((results) => {
            // results['Organization_Type']=[['NIH', true]];
            console.log(results);
            setOptionsWithResults(results);
        })
        .catch((err) =>
            console.error(`Error Occurred during fetch: ${err.message}`)
        )
        .finally(() => setLoading(false));

    queryWiserMatches( ID, dataString, currentUser)
        .then(({results, statusCode}) => {
            // results.data.data.unshift({
            //     contactInstitution: 'Colorado State University',
            //     contactTitle: '',
            //     contactName: 'Brian Geiss',
            //     contactPosition: 'Microbiology Professor',
            //     contactEmail: 'brian.geiss@colostate.edu',
            //     contactProfileLink: 'https://www.linkedin.com/in/brian-geiss-9233389%22',
            //     contactPhone: '(+91) 123-45678',
            //     contactAwardsCount: '10',
            //     title: null,
            //     description: null,
            //     location: null,
            //     employeeSize: null,
            //     totalFunding: null
            // });
            
            if(statusCode === 403){
                userLogOut();
                console.log(`${results}`);
                return;
            }

            setData((prevData) => ({
            ...prevData,
            currentPage: pageToQuery,
            resultsCount: results.data.totalCount,
            pageCount: results.data.totalPageCount,
            // countOfFilteredProjects: results.totalCount,
            list: results.data.data.length > 0 
                ? results.data.data?.map((info) => {
                    return {
                        Type: info?.organizationType,
                        Company_Name: info.title,
                        Pharmaceuticals: ['-'],
                        Description: info.description,
                        Locations: info.location,
                        Employee_Size: info.employeeSize,
                        Total_Funding: numFormatter(info.totalFunding),
                        // Count_of_Publications: 'N/A',
                        Match_Score: info?.compositeScore || 0,
                        PI_Institution: info.contactInstitution,
                        PI_Title: info.contactTitle,
                        PI_Name: info.contactName,
                        PI_Position: info.contactPosition,
                        PI_Email: info.contactEmail,
                        PI_Profile_Link: info.contactLinkedIn,
                        PI_Phone: info.contactPhone,
                        PI_Awards_Count: info.contactAwardsCount
                    }
                })
                : results.data.data
            }));
        })
        .catch((err) =>
            console.error(`Error Occurred during fetch: ${err.message}`)
        )
        .finally(() => setLoading(false));
    };

    const paginate = (number) => {
        queryFilteredData(number);
        scrollToListContent();
    };

    const scrollToListContent = () => {
        window.scrollTo({
        top: 0,
        behavior: "smooth",
        });
    };

    const handleSearchBarFocusCallback = (e) => {
        window.scrollTo(0, 0);
    };

    const handleBackBtnSubmit = () => {
        navigate(-1);
    };
    
    const FirstRecordOnPage = (data.currentPage - 1) * LIST_PAGE_RECORD_COUNT + 1;
    const LastRecordOnPage = Math.min(data.currentPage * LIST_PAGE_RECORD_COUNT, FirstRecordOnPage -1 + data.list.length);
    const TotalResults = data.resultsCount;

  return (
    <Layout
        title={'WISE-R Matches'}
        isLoaderDisplay={isLoading}
        filtersAlignCenter={true}
        footerRelative={true}
        searchbarPlaceholder={SEARCHBAR_PLACEHOLDER}
        handleSearchBarFocusCallback={handleSearchBarFocusCallback}
        handleSearchSubmit={queryFilteredData}
        handleSearchInput={(e) => setSearchText(e.target.value)}
        onBackClickCallback={handleBackBtnSubmit}
        filterOptions={options}
        filtersSelected={ !(data.list?.length == 1 && data.list[0]?.Type=='pi') && filters}
        handleClickFilters={setFilterByKey}
        handleResetFilters={resetFilters}
    >
      
        <ContentContainer>
            {data.pageCount > 0 && (
                <TopScrollBtn
                color="#2D6291"
                label={"To Top"}
                handleOnClick={scrollToListContent}
                />
            )}
            {
                data.list?.length > 0 && (
                    <div style={{color: '#6C7073', fontStyle: 'italic', lineHeight: '1.5em'}}>
                        <>{`Filtered by Rank Order of Match`}</><br/>
                        <>{`Showing ${FirstRecordOnPage} - ${LastRecordOnPage} of ${TotalResults} results`}</>
                    </div>
                )
            }
            <CardContainer>
                { data.list?.length > 0  
                    ? data.list?.map((item, index) => OrganizationCard(item, index)) 
                    : (
                        <h1 style={{color: '#827988', width: '100%', textAlign: 'center'}}>
                            No data Present at the moment
                        </h1>
                    ) }
            </CardContainer>
            <Pagination
            numberOfPages={data.pageCount}
            currentPage={data.currentPage}
            paginate={paginate}
            />
        </ContentContainer>
    </Layout>
  );
}

export default WiserMatches;
