import { Ratings } from "components";
import {
  Table,
  TableWrapper,
  Tbody,
  TdDiv,
  ThDiv,
  Thead,
  TitleTd,
  ViewResultButton,
} from "./style.components";
import { Link, useNavigate } from "react-router-dom";

const TableComponent = ({ tableData, handleFeedback }) => {
  const TABLE_HEADERS = [
    "ID",
    "Title",
    "Customer Rating",
    "Funding Entity",
    "Issued Date",
    "Expired Date",
    "WISE-R Score",
    "Source",
    "WISE-R Match",
  ];
  const TABLE_COLUMN_WIDTHS = ["8%", "30%", "8%", "8%", "8%", "9%", '8%' , "10%", "10%"];

  return (
    <TableWrapper>
      <Table>
        <Thead>
          <tr>
            {TABLE_HEADERS.map((header, index) => {
              return (
                <th key={"Table_Header_" + header} width={TABLE_COLUMN_WIDTHS[index]}>
                  <ThDiv>
                    <span>{header}</span>
                  </ThDiv>
                </th>
              );
            })}
          </tr>
        </Thead>
        <Tbody>
          <TableBody tableData={tableData} handleFeedback={handleFeedback} />
        </Tbody>
      </Table>
    </TableWrapper>
  );
};

const TableBody = ({ tableData, handleFeedback }) => {
  const navigate = useNavigate();

  return tableData.length > 0 ? (
    tableData.map((data, index) => {
      return (
        <tr key={index + "_" + data["ID"]}>
          <td>
            <TdDiv>{data["ID"]}</TdDiv>
          </td>
          <td>
          <TitleTd
            title={"Click to view opportunity details"}
            onClick={() =>
              navigate(`${data["ID"]}`, { state: { ID: data["ID"] } })
            }
          >
            {data["Title"]}
          </TitleTd>
          </td>

          <td style={{position: 'relative'}}>
            <TdDiv><Ratings initialValue={data['Feedback']} opportunityID={data["ID"]} handleFeedback={handleFeedback}/></TdDiv>
          </td>
          <td>
            <TdDiv title={data["Funding_Entity"]}>{data["Funding_Entity"]}</TdDiv>
          </td>
          <td>
            <TdDiv>{data["Issued_Date"]}</TdDiv>
          </td>
          <td>
            <TdDiv>{data["Expired_Date"]}</TdDiv>
          </td>
          <td>
            <TdDiv>{data["Wiser_Score"] ?? '-'}</TdDiv>
          </td>

          <td>
            <TdDiv>
              {
                (data['Source']===undefined || data['Source']===null) 
                ? <>{'N/A'}</>
                : (
                  <Link
                    to={data["Source"]}
                    target="_blank"
                    style={{ color: "#4A6697" }}
                  >
                    View Source Here
                  </Link>
                  )
              }
            </TdDiv>
          </td>

          <td style={{ whiteSpace: "nowrap" }}>
            <TdDiv>
              <ViewResultButton
                onClick={() =>
                  navigate(`${data["ID"]}/WiserMatches`, {
                    state: { ID: data["ID"] },
                  })
                }
              >
                View Matches
              </ViewResultButton>
            </TdDiv>
          </td>
        </tr>
      );
    })
  ) : (
    <></>
  );
};

export default TableComponent;
