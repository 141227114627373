import { TagsContainer, TagsContent, TagsHeading, TagsContentShort } from './style.tags';

const Tags = ({data, tagsKey="", overflowLength=25, clickHandler=null }) => {
    // console.log(color)
    return (
        data !== null && data !== undefined
            ? data.length > overflowLength ?
                <TagsContainer onClick={clickHandler}>
                    {
                        tagsKey && (
                            <TagsHeading>{tagsKey.split('_').join(' ')}</TagsHeading>
                        )
                    }
                    <TagsContent 
                        data-tooltip={data}
                    >
                        {data.slice(0, overflowLength - 10) + '...'}
                    </TagsContent>
                </TagsContainer>
                :
                <TagsContainer onClick={clickHandler}>
                    {
                        tagsKey && (
                            <TagsHeading>{tagsKey.split('_').join(' ')}</TagsHeading>
                        )
                    }
                    <TagsContentShort>{data}</TagsContentShort>
                </TagsContainer>
            : null
    );
}

export default Tags;