import { createSlice } from '@reduxjs/toolkit';

// Default options states
export const defaultOpportunitiesOptions = {
  Status: [],
  Published_Year: [],
  Funding_Entity: [],
  Recipient: [],
  Diseases: [],
  Technology: [],
  Organization_Type: [],
  Matches: []
};

export const defaultWiserMatchesOptions = {
  Organization_Types: [],
  Organization_Sizes: [],
};

export const defaultOrganizationOptions = {
  Diseases: [],
  Technology: [],
};

// Function to get default options based on page type
const getPagesDefaultOptionsState = (page) => {
  switch (page) {
    case 'Opportunities': return defaultOpportunitiesOptions;
    case 'Wiser Matches': return defaultWiserMatchesOptions;
    case 'Organization': return defaultOrganizationOptions;
    default: return defaultOpportunitiesOptions;
  }
};

// Redux Slice
const options = createSlice({
  name: 'options/opportunities',
  initialState: {},
  reducers: {
    initPageOptions: (state, action) => {
      const { page } = action.payload;
      if (!state[page]) {
        state[page] = getPagesDefaultOptionsState(page);
      }
    },
    setOptionsWithResults: (state, action) => {
      const { page, results } = action.payload;
      if (state[page]) {
        Object.keys(state[page]).forEach((key) => {
          if (key in results) state[page][key] = results[key];
        });
      }
    }
  }
});

// Export actions
export const { initPageOptions, setOptionsWithResults } = options.actions;

// Export reducer
export default options.reducer;
